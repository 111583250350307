import {SuppliesComponent} from "./interfaces/sseInterfaces";
import {titleCase} from "../../common/miscFunctions";
import React from "react";

function Supplies(props: SuppliesComponent) {
    return(
        <>
            <p className="caption-top fw-bold text-dark mb-0">Supplies</p>
            <div className="w-100" style={{overflowX: 'auto'}}>
                <table
                    className="table table-borderless table-responsive">
                    <thead>
                    <tr>
                        <th className="text-start d-table-cell" style={{width: '20%'}}>Date</th>
                        <th className="d-md-table-cell" style={{width: 'auto'}}>Item Name
                        </th>
                        <th className="hideCol" style={{width: 'auto'}}>Reference No.
                        </th>
                        <th className="d-none d-md-table-cell" style={{width: 'auto'}}>Quantity
                        </th>
                        <th className="d-md-none d-table-cell" style={{width: 'auto'}}>Qty
                        </th>
                        <th className="d-table-cell" style={{width: 'auto'}}>Price</th>
                        <th className="d-table-cell text-end" style={{width: 'auto'}}>Charge</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.supplies.map(filteredSup => {
                        return (
                            <tr>
                                <td className="text-start d-table-cell"
                                    style={{width: '20%'}}>{new Date(filteredSup.Date).toLocaleDateString()}</td>
                                <td className="d-table-cell" style={{width: 'auto'}}>{titleCase(filteredSup.ItemDescription)}</td>
                                <td className="hideCol" style={{width: 'auto'}}>{filteredSup.InvoiceNumber}</td>
                                <td className="d-table-cell"
                                    style={{width: 'auto'}}>{Number(filteredSup.ItemQuantity).toFixed(2)}</td>
                                <td className="d-table-cell"
                                    style={{width: 'auto'}}>{Number(filteredSup.ItemsPrice) === 0 ? props.formatter.format(filteredSup.ItemQuantity * filteredSup.ItemPrice) : props.formatter.format(Number(filteredSup.ItemPrice - (Number(filteredSup.ItemPrice) * Number(filteredSup.DiscountPercentage) / 100)))}</td>
                                <td className="d-table-cell text-end"
                                    style={{width: 'auto'}}>{props.formatter.format(filteredSup.ItemsPrice)}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default React.memo(Supplies);