import React, { useState } from 'react';
import {Link} from "react-router-dom";
import './stylesheets/Login.css';
import {useAuthenticationSubmit} from "./hooks/useAuthenticationSubmit";
import useWindowWidth from "../common/hooks/useWindowWidth";
import {useEnterKeySubmit} from "./hooks/useEnterKeySubmit";
import {useToastAutoDismiss} from "../common/hooks/useToastAutoDismiss";


function Login() {

    useToastAutoDismiss();
    const windowWidth = useWindowWidth();
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const authenticationSubmit = useAuthenticationSubmit({emailAddress, password}, windowWidth);
    const submitWithEnterKey = useEnterKeySubmit({emailAddress, password}, windowWidth);

    document.title = "Login | AD Solutions";

    return (
        <React.Fragment>
            <div className="login-component">
                <div className="login-card">
                    <div className="card-body m-4 ">
                        <h3 className="login-header">
                            Login
                        </h3>
                        <form onKeyDown={submitWithEnterKey}>
                            <div className="email-field-div form-outline">
                                <input type="email" name="emailAddress" id="emailAddress" className="input-fields form-control" value={emailAddress}
                                    onChange={(e) => setEmailAddress(e.target.value)}
                                    placeholder="Email Address" />
                            </div>
                            <div className="password-field-div form-outline">
                                <input type="password" id="password" name="password" className="input-fields form-control" value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Password" />
                            </div>
                            <div className="login-button-div">
                                <button type="button" onClick={authenticationSubmit} className="login-button btn">Login</button>
                            </div>
                            <div className="links">
                                <div><Link to="/forgot-password">Forgot password?</Link></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(Login);