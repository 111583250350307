import {LoginFormProps} from "../interfaces/authenticationInterfaces";
import {errorToast} from "../../common/toasts/toastMessages";

export function useAuthenticate(props: LoginFormProps, windowWidth: number) {

    const authenticate = async () => {
        try {
            if(props.emailAddress !== '' && props.password !== '') {
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/authentication/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                    },
                    body: JSON.stringify({emailAddress: props.emailAddress, password: props.password})
                });
                const data = await response.json();
                if (response.ok) {
                    localStorage.setItem('jwtToken', data.accessToken);
                    localStorage.setItem('emailAddress', props.emailAddress);
                    localStorage.setItem('temporary', data.temporary);
                } else {
                    errorToast(data.response, windowWidth);
                }
            } else {
                errorToast('Please enter your email address and password.', windowWidth);
            }

        } catch (error) {
            errorToast('There was an issue logging in. Please try again.', windowWidth);
        }
    }

    return {authenticate};
}