import React, {lazy, Suspense, useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import MainLayout from "./common/layout/MainLayout";
import AlternativeLayout from "./common/layout/AlternativeLayout";
import Loading from "./common/Loading";
import useWindowWidth from "./common/hooks/useWindowWidth";
import InternalRoutes from "./InternalRoutes";
import CustomerRoutes from "./CustomerRoutes";
import {useAuthStore} from "./auth/store/useAuthStore";
import Login from "./auth/Login";
import {errorToast} from "./common/toasts/toastMessages";

const LazyForgotPassword = lazy(() => import('./passwordReset/ForgotPassword'));

export default function App() {
    const windowWidth = useWindowWidth();

    const {setRole, setPermissions, setCompanyName, permissions} = useAuthStore((state) => ({
        setRole: state.setRole,
        permissions: state.permissions,
        setPermissions: state.setPermissions,
        setCompanyName: state.setCompanyName,
    }))

    const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const token = localStorage.getItem('jwtToken');

                console.log('Checking auth status. Time: ' + new Date().toLocaleTimeString());
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/authentication/check', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                console.log('Successfully checked auth status. Time: ' + new Date().toLocaleTimeString());

                if (!response.ok) {
                    localStorage.removeItem('jwtToken');
                    localStorage.removeItem('temporary');
                    setIsAuthenticated(false);
                } else {
                    setIsAuthenticated(true);
                    const data = await response.json();
                    setRole(data.role)
                    setPermissions(new Set(data.permissions));
                    setCompanyName(data.companyName);
                }

            } catch (error) {
                errorToast('There was an issue checking auth status. Please try again.', windowWidth);
            }
        }
        checkAuth();
    }, []);

    if(isAuthenticated === undefined) {
        return <Loading />
    }

    return (
        <Router>
            <Suspense fallback={<Loading />}>
                {(isAuthenticated) ? (
                    <>
                        {permissions.has('VIEW_EMPLOYEE_HOMEPAGE') && (
                            <InternalRoutes />
                        )}
                        {permissions.has('VIEW_CUSTOMER_HOMEPAGE') && (
                            <CustomerRoutes />
                        )}
                    </>
                ) : (
                    <>
                        <Routes>
                            <Route path="/forgot-password" element={<AlternativeLayout component={<LazyForgotPassword />} />} />
                            <Route path="/login" element={<MainLayout component={<Login />} />} />
                            <Route path="*" element={<Navigate to="/login" />} />
                        </Routes>
                    </>
                )}
            </Suspense>
        </Router>
    );
}
