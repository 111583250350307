import {LoginFormProps} from "../interfaces/authenticationInterfaces";
import {useAuthenticationSubmit} from "./useAuthenticationSubmit";
import React from "react";

export function useEnterKeySubmit(props: LoginFormProps, windowWidth: number) {

    const submit = useAuthenticationSubmit(props, windowWidth);

    return async (event:  React.KeyboardEvent) => {
        if (event.key === 'Enter' && props.emailAddress !== '' && props.password !== '') {
            event.preventDefault();
             await submit();
        }
    };
}