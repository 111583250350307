import React from "react";
import { motion, useTime, useTransform } from "framer-motion";
import logo from "./images/favicon.png";
import AlternativeLayout from "./layout/AlternativeLayout";


function Loading() {

    const time = useTime();
    const rotate = useTransform(time, [0, 4000], [0, 360], {clamp: false});
    return (
        <>
            <div className="d-flex align-content-center justify-content-center pb-4"
                 style={{minHeight: '100vh'}}>
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="pb-2" style={{fontSize: '1.5rem', fontWeight: 'lighter'}}>Loading ...</div>
                    <div>
                        <motion.div style={{rotate}}>
                            <img src={logo} alt="logo" style={{width: '50px', height: '50px'}}/>
                        </motion.div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default React.memo(Loading);