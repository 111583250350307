import {LoginFormProps} from "../interfaces/authenticationInterfaces";
import {useAuthenticate} from "./useAuthenticate";

export function useAuthenticationSubmit(props: LoginFormProps, windowWidth: number) {

    const authenticate = useAuthenticate(props, windowWidth);

    return async () => {
        await authenticate.authenticate();

        if (localStorage.getItem('temporary') === "true") {
            window.location.href = '/update-password';
        } else if (localStorage.getItem('jwtToken') !== null){
            window.location.href = '/';
        }
    };
}